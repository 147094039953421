<template>
  <panel
    :breadcrumbs="breadcrumbs"
    :title="title"
  >
    <v-toolbar card>
      <v-spacer />
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
      />
    </v-toolbar>
    <v-alert
      :value="alert"
      :type="alertType"
      transition="scale-transition"
    >
      {{ alertMessage }}
    </v-alert>
    <v-data-table
      :headers="headers"
      :items="items"
      :loading="loading"
      :search="search"
      item-key="name"
      class="elevation-1"
      disable-initial-sort
    >
      <template #items="props">
        <tr>
          <td class="text-xs-left">
            {{ props.item.name }}
          </td>
          <td class="text-xs-left">
            {{ props.item.alamat }}
          </td>
          <td class="text-xs-left">
            {{ props.item.contact }}
          </td>
          <td class="text-xs-left">
            {{ props.item.parent }}
          </td>
          <td class="text-xs-left">
            {{ props.item.parent2 }}
          </td>
          <td class="text-xs-left">
            {{ props.item.sek }}
          </td>
        </tr>
      </template>
    </v-data-table>
  </panel>
</template>

<script>
import { createGetParams } from '@/rest'

const title = 'Student List'

export default {
  components: {
    Panel: () => import(/* webpackChunkName: "panel" */ '@/components/Panel'),
  },
  data () {
    return {
      alert: false,
      alertMessage: null,
      alertType: 'error',
      breadcrumbs: [
        {
          text: 'Jom Bantu', disabled: false, to: '/jombantu',
        },
        {
          text: title, disabled: true,
        },
      ],
      items: [],
      headers: [
        {
          text: 'Name',
          align: 'left',
          sortable: true,
          value: 'name',
        },
        {
          text: 'Address',
          align: 'left',
          sortable: true,
          value: 'alamat',
        },
        {
          text: 'Contact No.',
          align: 'left',
          sortable: true,
          value: 'contact',
        },
        {
          text: 'Father/Guardian Name',
          align: 'left',
          sortable: true,
          value: 'parent',
        },
        {
          text: 'Mother Name',
          align: 'left',
          sortable: true,
          value: 'parent2',
        },
        {
          text: 'School',
          align: 'left',
          sortable: true,
          value: 'sek',
        },
      ],
      loading: false,
      search: '',
      title: title,
    }
  },
  mounted: function () {
    this.getJomBantuListStudent()
  },
  methods: {
    getJomBantuListStudent: function () {
      this.loading = false
      this.alert = false
      this.$rest.get('getJomBantuListStudent.php', createGetParams())
        .then(function (response) {
          this.items = response.data.item
        }.bind(this)).catch(error => {
          let message = error.message
          if (error.response) {
            message += ': ' + error.response.data
          }
          this.alertMessage = message
          this.alert = true
          this.loading = false
        })
    },
  },
}
</script>
